<template>
<div>
    <div class="row">
        <div class="col-12">
            <div class="row mb-3">
                <div class="col-lg-4">
                    <div class="mb-3 mb-lg-0">
                        <a class="btn text-white btn-primary" href="javascript: void(0);" @click="popupAddModal = true">
                            Create a language group
                        </a>
                    </div>
                </div>
                <div class="col-lg-8">
                    <form class="form d-flex justify-content-end">
                        <div class="mr-2 flex-grow-1">
                            <label class="d-block">
                                <b-form-input v-model="searchQuery" @input="updateSearchQuery" type="search" 
                                placeholder="Search..." class="form-control"></b-form-input>
                            </label>
                        </div>
                        <div class="">
                            <b-dropdown variant="secondary" right>
                                <template slot="button-content">
                                    <i class="mdi mdi-filter-variant"></i>
                                </template>
                                <b-dropdown-item-button @click="sortBy = 'asc'"> Ascending </b-dropdown-item-button>
                                <b-dropdown-item-button @click="sortBy = 'desc'"> Descending </b-dropdown-item-button>
                                <b-dropdown-item-button @click="defaultStatus = 'pending'"> Pending </b-dropdown-item-button>
                                <b-dropdown-item-button @click="defaultStatus = 'approved'"> Approved </b-dropdown-item-button>
                                <b-dropdown-item-button @click="defaultStatus = 'suspended'"> Suspended </b-dropdown-item-button>
                                <b-dropdown-item-button @click="defaultStatus = 'all'"> View all </b-dropdown-item-button>
                            </b-dropdown>
                        </div>
                    </form>
                </div>
            </div>
            <div class="mb-2"><strong>Filters:</strong> <span class="text-uppercase">Sort: {{sortBy}}</span> | <span class="text-uppercase"> Status: {{defaultStatus}} </span></div>
        </div>
    </div>
    <is-loading v-if="isLoading" />
    <div v-else>
        <div class="row">
            <div class="col-md-6" v-for="(group, index) in groups" :key="index">
                <group-card :item="group" />
            </div>
        </div>
        <div class="row mt-3 mb-4">
            <div class="col-12 d-flex justify-content-center">
                <div class="pagination-rounded">
                    <b-pagination v-model="currentPage" class="pagination-rounded mb-0"
                    :total-rows="pageData.total" :limit="3" :per-page="itemsPerPage"></b-pagination>
                </div>
            </div>
        </div>
    </div>
    <b-modal centered id="modal-1" v-model="popupAddModal" title="Create a Language Group" header-close-variant="primary" title-class="font-18" hide-footer>
        <group-form @resetForm="initializeAll()"/>
    </b-modal>
</div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue';
import GroupForm from "@/components/forms/GroupForm.vue"
import GroupCard from '@/components/cards/GroupCard.vue';
import _ from 'lodash';

export default {
    components: {
        IsLoading,
        GroupForm,
        GroupCard
    },
    data() {
        return {
            isLoading: false,
            popupAddModal: false,
            sortBy: "asc",
            defaultStatus: "all",
            searchQuery: "",
        };
    },
    watch: {
        defaultStatus (val) {
            this.fetchItems()
        },
        sortBy (val) {
            this.fetchItems()
        }
    },
    computed: {
        pageData(){
            return this.$store.state.groupList.pageData
        },
        groups() {
            return this.pageData.data
        },
        currentPage: {
            get() {
                return this.pageData.current_page
            },
            set(val) {
                if(!this.pageData.current_page || this.pageData.current_page == val) return
                this.fetchItems(val)
            }
        },
        itemsPerPage: {
            get() {
                return +this.pageData.per_page || 10
            },
            set(val) {
                this.fetchItems(1, val)
            }
        },
    },
    methods: {
        initializeAll(){
            this.popupAddModal = false
        },
        updateSearchQuery: _.debounce(function(string) {
            this.fetchItems()
        }, 2000),
        fetchItems(page, per_page = null){
            let payload = {page: page || 1, sort_by: this.sortBy, per_page: per_page || this.itemsPerPage }
            if(this.defaultStatus != 'all'){ payload.status = this.defaultStatus }
            if(this.searchQuery){ payload.search = this.searchQuery }
            this.isLoading = true
            this.$store.dispatch("groupList/fetchGroups", payload)
            .then(response => this.isLoading = false)
        }
    },
    mounted(){
        this.fetchItems()
        if(this.$route.query.create){
            this.popupAddModal = true
        }
    }
};
</script>


