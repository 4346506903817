<template>
  <div id="create-group">
    <div class="row justify-content-start">
        <div class="col-12">
            <div class="form-group mb-3">
                <label for="group_name">
                    Name
                    <span class="text-danger">*</span>
                </label>
                <input type="text" v-validate="{ required: true}" v-model="data.name" id="group_name"
                 name="name" class="form-control" :class="{ 'is-invalid': errors.has('name') }" placeholder="Language name" />
                <span class="text-danger invalid-feedback"  v-show="errors.has('name')">{{ errors.first('name') }}</span>
            </div>

            <div class="form-group mb-3">
                <label>Countries</label>
                <multiselect
                    :options="countries.map(c => c.id)"
                    :custom-label="parseCountry"
                    v-model="data.countries"
                    :multiple="true"
                    placeholder="--select--"
                    :close-on-select="false"
                    :clear-on-select="false"
                ></multiselect>
            </div>

            <div class="form-group mb-3">
                <label for="region">
                    Region
                    <span class="text-danger">*</span>
                </label>
                <input type="text" v-validate="{ required: true}" v-model="data.region" id="region"
                 name="region" class="form-control" :class="{ 'is-invalid': errors.has('region') }" placeholder="Region(s)" />
                <span class="text-danger invalid-feedback"  v-show="errors.has('region')">{{ errors.first('region') }}</span>
            </div>

            <div class="form-group mb-3">
                <label for="description">
                    Description
                </label>
                <textarea id="description" v-validate="'max:1000'" v-model="data.description" class="form-control"
                 rows="3" name="description" placeholder="Write description"></textarea>
                <span class="text-danger invalid-feedback"  v-show="errors.has('description')">{{ errors.first('description') }}</span>
            </div>

            <div class="form-group text-left mt-3 mb-0">
                <button class="btn btn-primary" @click.prevent="submitForm"  type="button">Submit</button>
            </div>
        </div>
    </div>

  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default{
  components: {
    Multiselect,
  },
  data() {
    return {
      data: {
        name: "",
        description: "",
        region: "",
        countries:[]
      },

    }
  },
  computed: {
    countries(){
      return this.$store.state.countries;
    }
  },
  methods: {
    parseCountry(id){
      let con = this.countries.find(item => item.id == id);
      if(con){ return con.name; }
    },
    submitForm() {
      this.$validator.validateAll().then(result => {
        if (!result) { this.alertError("Form Not Valid"); return;}
        const formData = new FormData()
        formData.append('data', JSON.stringify(this.data))
        this.$store.dispatch("changeLoaderValue", true)
        this.$http.post("/groups/create", formData)
        .then((response)=>{
          this.$store.dispatch("changeLoaderValue", false)
          if(response.data.success){
            this.$store.commit('groupList/ADD_GROUP', response.data.data)
            this.resetForm()
          }
        })
      });
    },
    resetForm() {
      this.data = {
        name: "",
        description: ""
      }
      this.$emit('resetForm');
      this.$validator.reset()
    },
  },
}
</script>
