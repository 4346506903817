<template>
<div class="card">
    <div class="card-body">
        <router-link :to="`/languages/${item.id}`" class="text-dark">
        <div class="media align-items-center">
            <div class="avatar-sm mr-3">
                <div class="avatar-title bg-light rounded text-body font-20 font-weight-semibold">{{ item.name.charAt(0) }}</div>
            </div>
            <div class="media-body">
                <h5 class="my-1">
                    {{item.name}}
                </h5>
            </div>
        </div>
        </router-link>
        <p class="text-muted my-1">
            <i class="mdi mdi-flag-checkered mr-1"></i>
            <span>{{(item.countries.length>1)? "Countries": "Country"}}:</span> {{item.countries.map((i) => i.name).join(', ')  | truncate(25)}}
        </p>
        <p class="text-muted mb-0">
            <i class="mdi mdi-flare mr-1"></i>
            <span>Region:</span> {{item.region | truncate(35)}}
        </p>
        <hr class="my-2"/>
        <div class="text-muted">
            <div class="row">
                <div class="col-6">
                    <div>
                        <p class="text-truncate mb-1 font-14">Founder</p>
                        <h5 class="my-0 font-14" v-if="item.founder">
                            {{ item.founder.username }}
                        </h5>
                    </div>
                </div>
                <div class="col-6">
                    <div>
                        <p class="text-truncate mb-1 font-14">Status</p>
                        <span v-html="statusBadge(item.status)"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props:{
        item:{
            type: Object,
            required: true
        }
    }
}
</script>

<style>

</style>